/**产品页首屏
 * pcImg mobileImg logo  传入对应图片链接
 * desc backgroundColor title 传入字符串
 * textBlack 传入true or false 默认true，文本颜色黑色
 * logo 和 title 二选一，如有一个没有 就传空
 */

import React, { useEffect, useState } from 'react'
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import Img from "gatsby-image";
const ProFirst = ({ msg,shop_sale }) => {
    const { pcImg, mobileImg, logo, desc, backgroundColor, textBlack, title } = msg
    const ifPc = useBigScreen(768)
    const [loaded, setLoaded] = useState(false);


    return (
        <div className={style.first}
            style={{
                backgroundColor: backgroundColor,
                // backgroundImage:ifPc?`url(${pcImg})`:''
            }}
        >
            {ifPc && <Img
                fluid={{
                    src: pcImg,
                }}
                alt="Blurred Image"
                style={{
                    // filter: loaded ? 'none' : 'blur(20px)', // 如果加载完成，取消模糊效果
                    // transition: 'filter 0.3s ease-in-out',
                    width: "100%", height: "100vh",
                    position: "absolute",
                    top: "0",
                    zIndex: "1",
                 

                }}
                fadeIn={true}
                onLoad={() => setLoaded(true)}
            />}

            <div className={style.firstTextBox}>
                <div className={style.firstText}>
                    {!!logo && <img src={logo} />}
                    {!!title &&
                        <div className={style.firstTextTitle}
                            style={{ color: textBlack ? '#111' : "#fff" }}
                        >{title}</div>}

                    <h1 className={style.firstTextDesc}  dangerouslySetInnerHTML={{ __html: desc }}
                        style={{ color: textBlack ? '#111' : "#fff" }}
                    ></h1>

                    {shop_sale&& <div className={style.price}>
                        {msg.price_sale}<span>  {msg.price_origin}</span>
                    </div>}
                   
                </div>
            </div>

            {/* {!ifPc&&<div className={style.firstImg} style={{
                 backgroundImage:`url(${mobileImg})`
            }}></div>} */}
            {!ifPc &&
                <div className={style.firstMobileImg}>
                    <Img
                        fluid={{
                            src: mobileImg,
                            // maxWidth: ,


                        }}
                        alt="Blurred Image"
                        style={{
                            filter: loaded ? 'none' : 'blur(20px)', // 如果加载完成，取消模糊效果
                            transition: 'filter 0.3s ease-in-out',
                            width: "100%",
                            height: 'auto',
                        }}
                        fadeIn={true}
                        onLoad={() => setLoaded(true)}
                    />
                </div>
            }
        </div>
    )
}



export default ProFirst