import React from "react"
import style from "./index.module.less"
import { useIntl } from "react-intl"

import {
  ada_device_ios,
  ada_device_ps,
  ada_device_xbox,
  ada_device_switch
} from '@/images/ada/index';

const Intro = () => {
  const intl = useIntl()
  return (
    <>
      <div className={style.IntroBox}>
        <div className={style.IntroPart2}>
          <h2 className={style.IntroTitle} >{intl.formatMessage({ id: 'ada.plugplay' })}</h2>
          <div className={style.HDMIBox}>
            <div className={style.HDMIBoxItem}>
              <img src={ada_device_ios} />
              <h3 className={style.HDMIBoxItemTitle}>{intl.formatMessage({ id: 'ada.plug' })}</h3>
              <h4 className={style.HDMIBoxItemText}>{intl.formatMessage({ id: 'ada.plug.des' })}</h4>
              <span className={style.HDMIBoxItemText2}>{intl.formatMessage({ id: 'ada.plug.comments' })}</span>
            </div>
            <div className={style.HDMIBoxItem}>
              <div className={style.HDMIBoxItemPic}>
                <img src={ada_device_ps} />
                <img src={ada_device_xbox} />
                <img src={ada_device_switch} />
              </div>
              <span className={style.HDMIBoxItemTitle}>{intl.formatMessage({ id: 'ada.play' })}</span>
              <span className={style.HDMIBoxItemText}>{intl.formatMessage({ id: 'ada.play.des' })}</span>
              <span className={style.HDMIBoxItemText2}>{intl.formatMessage({ id: 'ada.play.comments' })}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Intro