import React, { useState, useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import classNames from "classnames"
import style from './index.module.less'

const JPIntro = () => {

    const intl = useIntl();

    const list = [{
        label: intl.formatMessage({ id: "adapter_page4_text1" }),
        text: [intl.formatMessage({ id: "adapter_page4_text1_des1" })]
    }, {
        label: intl.formatMessage({ id: "adapter_page4_text2" }),
        text: [intl.formatMessage({ id: "adapter_page4_text2_des1" })]
    }, {
        label: intl.formatMessage({ id: "adapter_page4_text3" }),
        text: [ intl.formatMessage({ id: "adapter_page4_text3_des1" }), intl.formatMessage({ id: "adapter_page4_text3_des2" }), intl.formatMessage({ id: "adapter_page4_text3_des3" })]
    }, {
        label: intl.formatMessage({ id: "adapter_page4_text4" }),
        text: [intl.formatMessage({ id: "adapter_page4_text4_des1" }), intl.formatMessage({ id: "adapter_page4_text4_des2" })]
    }, {
        label:intl.formatMessage({ id: "adapter_page4_text5" }),
        text: [intl.formatMessage({ id: "adapter_page4_text5_des1" }), intl.formatMessage({ id: "adapter_page4_text5_des2" }), intl.formatMessage({ id: "adapter_page4_text5_des3" }), intl.formatMessage({ id: "adapter_page4_text5_des4" })]
    }, {
        label: intl.formatMessage({ id: "adapter_page4_text6" }),
        text: [intl.formatMessage({ id: "adapter_page4_text6_des1" })]
    }]


    
    return (
        <div className={style.intro}>
            <div className={style.introTitle}>{intl.formatMessage({ id: "adapter_page4_title" })}</div>
            <div className={style.introContent}>
                {list.map(item => (
                    <div className={style.introContentItem} key={item.label}>
                        <div className={style.introContentItemLabel}>{item.label}</div>
                        {item?.text?.map(ite => (
                            <div className={style.introContentItemText} key={ite}>{ ite }</div>
                        ))}
                    </div>
                ))}
            </div>

        </div>
    )
}

export default JPIntro