import React, { useState, useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import classNames from "classnames"
import style from './index.module.less'

const JPNotes = () => {

    const intl = useIntl();

    return (
        <div className={style.notes}>
            <div> {intl.formatMessage({ id: "adapter_page4_detail1" })}</div>
            <div>{intl.formatMessage({ id: "adapter_page4_detail2" })}</div>
            <div>{intl.formatMessage({ id: "adapter_page4_detail3" })}</div>
            <div>{intl.formatMessage({ id: "adapter_page4_detail4" })}</div>
            <div>{intl.formatMessage({ id: "adapter_page4_detail5" })}</div>
            <div>{intl.formatMessage({ id: "adapter_page4_detail6" })}</div>
        </div>
    )
}

export default JPNotes