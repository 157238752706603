import React from 'react'
import style from './index.module.less'
import { AdaHeader1_black, AdaHeader2_black } from '../../../images/ada/index'
import { useIntl } from "react-intl"

const Update = () =>{
    const intl = useIntl()
    const update = [
        {
            img:AdaHeader1_black,
            desc:intl.formatMessage({ id: `ada.tips.1`, defaultMessage: "Designed for compatibility with the official Apple Lightning Digital AV Adapter which must be purchased separately." }),
            tip:intl.formatMessage({ id: "ada.tips.3", defaultMessage: "Only Support XREAL Air" })
        },
        {
            img:AdaHeader2_black,
            desc:intl.formatMessage({ id: `ada.tips.2`, defaultMessage: "Please be sure to activate and update XREAL Air to latest Firmware through XREAL website prior to use Adapter. " })
        }
    ]
    return(
        <div className={style.update}>
            <div className={style.updateBox}>
                {update.map((item,index)=>(
                    <div className={style.updateBoxItem} key={`adapterUpdate${index}`}>
                        <img src={item.img} />
                        <div className={style.updateBoxItemDesc}
                        dangerouslySetInnerHTML={{ __html: item.desc}}
                        ></div>
                        <div className={style.updateBoxItemTip}
                        >{item.tip}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Update