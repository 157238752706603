import React from "react"
import { useIntl } from "react-intl"
import style from './index.module.less'
import {
    ada_view1_jp,
    ada_view2_jp,
    ada_view3_jp,
    ada_view4_jp,
    ada_view5_jp,
    ada_view6_jp,
    ada_view7_jp,
} from '@/images/ada/index'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Mousewheel, FreeMode, Pagination, Navigation } from "swiper";

const JPSwiperView = () => {

    const intl = useIntl();
    const modules = [Autoplay, FreeMode, Mousewheel, Pagination, Navigation];
    const list = [{
        img: ada_view1_jp,
    }, {
        img: ada_view2_jp,
    }, {
        img: ada_view3_jp,
    }, {
        img: ada_view4_jp,
    }, {
        img: ada_view5_jp,
    }, {
        img: ada_view6_jp,
    }, {
        img: ada_view7_jp,
    }]

    return (
        <div className={style.page}>
            <div className={style.pageTitle}>{intl.formatMessage({ id: "adapter_page3_swiper" })}</div>
            <Swiper
                modules={modules}
                loop={true}
                navigation={true}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
            >
                {list.map(item => (<SwiperSlide key={item.img}>
                    <img src={item.img} />
                </SwiperSlide>))}

            </Swiper >
        </div >
    )
}

export default JPSwiperView