import React, { useState, useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import classNames from "classnames"
import style from './index.module.less'
import {
    ada_use1_jp,
    ada_use2_jp,
} from '@/images/ada/index'

const JPUse = () => {

    const intl = useIntl();

    const list = [{
        img: ada_use1_jp,
        title1: intl.formatMessage({ id: "adapter_page3_text1" }),
        title2: intl.formatMessage({ id: "adapter_page3_text1Des" }),
        text: intl.formatMessage({ id: "adapter_page3_text1Des2" }),
    }, {
        img: ada_use2_jp,
        title1: intl.formatMessage({ id: "adapter_page3_text2" }),
        title2: intl.formatMessage({ id: "adapter_page3_text2Des" }),
        text: intl.formatMessage({ id: "adapter_page3_text2Des2" }),
    }]

    return (
        <div className={style.use}>
            <h2 className={style.useTitle}>{intl.formatMessage({ id: "adapter_page3_title" })}</h2>
            <div className={style.useContent}>
                {list.map(item => (<div className={style.useContentItem} key={item.img}>
                    <img className={style.useContentItemImg} src={item.img} />
                    <div className={style.useContentItemTitle}>{item.title1}</div>
                    <div className={style.useContentItemText}>{item.title2}</div>
                    <div className={style.useContentItemText}>{item.text}</div>
                </div>))}
            </div>
        </div>
    )
}

export default JPUse