import React from 'react';
import style from './index.module.less'
import { useIntl } from "react-intl"


const Detail = () => {
    const intl = useIntl()
    const DetailText = [
        // {
        //     title:'外观',
        //     desc:['白色']
        // },
        {
            title: intl.formatMessage({ id: 'adapter_page4_text1' }),
            desc: [intl.formatMessage({ id: 'adapter_page4_text1_des1' }), intl.formatMessage({ id: 'adapter_page4_text1_des2' }), intl.formatMessage({ id: 'adapter_page4_text1_des3' }), intl.formatMessage({ id: 'adapter_page4_text1_des4' })]
        },
        {
            title: intl.formatMessage({ id: 'adapter_page4_text2' }),
            desc: [intl.formatMessage({ id: 'adapter_page4_text2_des1' }), intl.formatMessage({ id: 'adapter_page4_text2_des2' }), intl.formatMessage({ id: 'adapter_page4_text2_des3' }), intl.formatMessage({ id: 'adapter_page4_text2_des4' })]
        },
        {
            title: intl.formatMessage({ id: 'adapter_page4_text3' }),
            desc: [intl.formatMessage({ id: 'adapter_page4_text3_des1' })]
        },
        {
            title: intl.formatMessage({ id: 'adapter_page4_text4' }),
            desc: [intl.formatMessage({ id: 'adapter_page4_text4_des1' })]
        },
        {
            title: intl.formatMessage({ id: 'adapter_page4_text5' }),
            desc: [intl.formatMessage({ id: 'adapter_page4_text5_des1' }), intl.formatMessage({ id: 'adapter_page4_text5_des2' }), intl.formatMessage({ id: 'adapter_page4_text5_des3' }), intl.formatMessage({ id: 'adapter_page4_text5_des4' })]
        },
        {
            title: intl.formatMessage({ id: 'adapter_page4_text6' }),
            desc: [intl.formatMessage({ id: 'adapter_page4_text6_des1' })]
        }

    ]
    return (
        <div className={style.container}>
            <div className={style.containerBox}>
                <h2 className={style.title}>{intl.formatMessage({ id: "adapter_page4_title" })}</h2>
                <div className={style.contentBox}>
                    {DetailText.map((item, index) => (
                        <div key={item.title} className={style.contentColum}>
                            <h3 className={style.contentColumTitle}>{item.title}</h3>
                            <div className={style.contentColumDesc}>
                                {item.desc.map((val, index_des) => (
                                    <div key={`${item.title}${index_des}`} dangerouslySetInnerHTML={{ __html: `${val}` }}>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={style.tips}>
                    <div className={style.tipsText}>{intl.formatMessage({ id: "adapter_page4_detail1" })}</div>
                    <div className={style.tipsText}>{intl.formatMessage({ id: "adapter_page4_detail2" })}</div>
                    <div className={style.tipsText}>{intl.formatMessage({ id: "adapter_page4_detail3" })}</div>
                    <div className={style.tipsText}>{intl.formatMessage({ id: "adapter_page4_detail4" })}</div>
                    <div className={style.tipsText}>{intl.formatMessage({ id: "adapter_page4_detail5" })}</div>

                </div>
                <div className={style.tipsTextBottom} >
                    {intl.formatMessage({ id: "adapter_page4_tip1" })}
                    <br />
                    {intl.formatMessage({ id: "adapter_page4_tip2" })}
                </div>

            </div>
        </div>
    )
}

export default Detail