import React from "react"
import { useIntl } from "react-intl"
import style from './index.module.less'
import {
    ada_banner1_jp,
    ada_banner2_jp,
} from '@/images/ada/index'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Mousewheel, FreeMode, Pagination } from "swiper";

const JPSwiperBanner = () => {

    const intl = useIntl();
    const modules = [Autoplay, FreeMode, Mousewheel, Pagination];

    return (
        <div className={style.swiperContainer}>
            <Swiper
                modules={modules}
                loop={true}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
            >
                <SwiperSlide style={{ backgroundImage: `url(${ada_banner1_jp})` }}>
                    <div className="swiper_slide_title">
                        {intl.formatMessage({ id: "adapter_page2_swiper1" })}
                    </div>
                    <div className="swiper_slide_title">{intl.formatMessage({ id: "adapter_page2_swiper1_1" })}</div>
                    <div className="swiper_slide_text">* {intl.formatMessage({ id: "adapter_page2_swiper1Des" })}</div>

                </SwiperSlide>
                <SwiperSlide style={{ backgroundImage: `url(${ada_banner2_jp})` }}>
                    <div className="swiper_slide_title">{intl.formatMessage({ id: "adapter_page2_swiper2" })}</div>
                    <div className="swiper_slide_title">{intl.formatMessage({ id: "adapter_page2_swiper2Des" })}</div>
                    <div className="swiper_slide_text"></div>
                </SwiperSlide>

            </Swiper >
        </div >
    )
}

export default JPSwiperBanner